import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ImageInputField } from './ImageInputField';
import { ReactComponent as DamagedInsideIcon } from 'assets/claims/item-damaged-inside.svg';
import { ReactComponent as DamagedOutsideIcon } from 'assets/claims/item-damaged-outside.svg';
import { ItemPhotoType, PhotoType, ClaimType } from '../types';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '16px',
  },
}));

interface Props {
  index: number;
}

const ItemPhotoSection = ({ index }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ImageInputField
        name={`items[${index}].damagedPhoto[${ItemPhotoType.Inside}]`}
        icon={<DamagedInsideIcon />}
        text="Damaged item inside the box"
        photoType={PhotoType.DamagedItemInside}
        sectionType="item"
        claimType={ClaimType.Damaged}
        itemIndex={index}
      />

      <ImageInputField
        name={`items[${index}].damagedPhoto[${ItemPhotoType.Outside}]`}
        icon={<DamagedOutsideIcon />}
        text="Damaged item outside the box"
        photoType={PhotoType.DamagedItemOutside}
        sectionType="item"
        claimType={ClaimType.Damaged}
        itemIndex={index}
      />
    </div>
  );
};

export default ItemPhotoSection;
