import React from 'react';
import { LogoImage } from 'components/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  providerLogo: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
  },
}));

const ClaimSuccessNotification = (props) => {
  const { account } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LogoImage src={account.provider.logoUrl} className={classes.providerLogo} />
      Your claim has been submitted and is processing.
    </div>
  );
};

export default ClaimSuccessNotification;
