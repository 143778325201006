import React, { ReactNode } from 'react';
import { Field, FieldProps } from 'react-final-form';
import { makeStyles, FormControl, FormLabel, RadioGroup as MuiRadioGroup } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  label: {},
}));

type Props<T extends string> = FieldProps<T, any> & {
  label: string;
  labelClass?: string;
  children: ReactNode;
};

const RadioGroup = <T extends string>({ label, labelClass, children, ...restProps }: Props<T>) => {
  const classes = useStyles();

  return (
    <Field
      render={({ input }) => {
        return (
          <FormControl component="fieldset">
            <FormLabel component="label" className={classNames(classes.label, labelClass)}>
              {label}
            </FormLabel>
            <MuiRadioGroup value={input.value}>{children}</MuiRadioGroup>
          </FormControl>
        );
      }}
      {...restProps}
    />
  );
};

export default RadioGroup;
