import React, { PropsWithChildren, ReactNode } from 'react';
import { Card, makeStyles } from '@material-ui/core';
import { CardTitle } from 'components/common';

interface Props {
  title: string;
  icon?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
}));

const SectionCard = ({ title, icon, children }: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardTitle icon={icon} classes={{ root: classes.cardTitle }}>
        {title}
      </CardTitle>
      {children}
    </Card>
  );
};

export default SectionCard;
