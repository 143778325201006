import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

import { LogoImage } from 'components/common';

import useCustomNotify from 'hooks/useCustomNotify';

import { ReactComponent as Arrow } from 'assets/general/icons/ArrowRight.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  providerLogo: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
  },

  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  redirectLink: {
    display: 'flex',
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
}));

interface Props {
  [key: string]: any;
  record?: Rollo.ShipmentDTO;
}

const ClaimNotification = ({ record }) => {
  const classes = useStyles();

  const { account, claimableStatus } = record || {};

  return (
    <div className={classes.container}>
      <LogoImage src={account.provider.logoUrl} className={classes.providerLogo} />
      <div className={classes.description}>
        <Typography variant="body1" align="left">
          {claimableStatus.description}
        </Typography>
        {claimableStatus.redirection && (
          <Typography
            variant="body1"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={claimableStatus.redirection}
            className={classes.redirectLink}>
            {claimableStatus.redirectionText}
            <Arrow />
          </Typography>
        )}
      </div>
    </div>
  );
};

export const OpenClaimActionField = (props: Props) => {
  const history = useHistory();
  const { record } = props || {};

  const notify = useCustomNotify();

  if (!record || !record?.claimableStatus) {
    return null;
  }

  const handleClick = () => {
    if (record?.claimableStatus.claimable) {
      history.push(
        `/claim/${record?.account?.provider?.code === 'USPS' ? 'usps' : 'ups'}/${record.id}`
      );
    } else {
      notify(<ClaimNotification record={record} />, 'claim');
    }
  };

  return <MenuItem onClick={handleClick}>Open claim</MenuItem>;
};

export const OpenClaimButtonField = ({ record, ...props }) => {
  const history = useHistory();
  const notify = useCustomNotify();

  const handleClick = () => {
    if (record?.claimableStatus.claimable) {
      history.push(
        `/claim/${record?.account?.provider?.code === 'USPS' ? 'usps' : 'ups'}/${record.id}`
      );
    } else {
      notify(<ClaimNotification record={record} />, 'claim');
    }
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      <FileCopyOutlined />
    </IconButton>
  );
};
