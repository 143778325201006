import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      boxSizing: 'border-box',
      marginRight: theme.spacing(2),
    },

    image: {},

    sizeSmall: {
      '&$root': {
        width: theme.spacing(5.5),
        height: theme.spacing(5.5),
      },

      '& $image': {
        maxWidth: theme.spacing(3.5),
        maxHeight: theme.spacing(3.5),
      },
    },

    sizeMedium: {
      '&$root': {
        width: theme.spacing(7.5),
        height: theme.spacing(7.5),
      },

      '& $image': {
        maxWidth: theme.spacing(5.5),
        maxHeight: theme.spacing(5.5),
      },
    },
  });

const LogoImage = ({
  className,
  classes,
  src,
  alt,
  small,
}: {
  className?: string;
  classes: any;
  src: string;
  alt?: string;
  small?: boolean;
}) => (
  <div
    className={classNames(
      classes.root,
      small && classes.sizeSmall,
      !small && classes.sizeMedium,
      className
    )}>
    <img className={classes.image} src={src} alt={alt} />
  </div>
);

export default withStyles(styles)(LogoImage);
