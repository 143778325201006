import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import { Radio as MuiRadio, makeStyles, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {},
}));

type Props<T extends string> = FieldProps<T, any> & {
  label: string;
};

const Radio = <T extends string>({ label, ...restProps }: Props<T>) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.label}
      label={label}
      control={
        <Field
          type="radio"
          render={({ input }) => {
            return (
              <MuiRadio
                color="primary"
                name={input.name}
                value={input.value}
                onChange={input.onChange}
              />
            );
          }}
          {...restProps}
        />
      }
    />
  );
};

export default Radio;
