import snakeCase from 'lodash/snakeCase';

export const sealMaterials = [
  'Cable',
  'Cap',
  'Clasp',
  'Hinge',
  'Glue',
  'Latch',
  'Nails',
  'None',
  'Staples',
  'Stitch',
  'Straps',
  'String',
  'Tape',
  'Ties',
  'Twist',
  'Zipper',
  'Other',
].map((item) => ({
  value: item === 'Other' ? 'CUSTOM' : snakeCase(item).toUpperCase(),
  label: item,
}));

export const packaging = [
  'Airbags',
  'Wrap',
  'Corrugated',
  'Double Boxed',
  'Foam',
  'Loose Fill',
  'Paper',
  'Peanuts',
  'None',
  'Other',
].map((item) => ({
  value: item === 'Other' ? 'CUSTOM' : snakeCase(item).toUpperCase(),
  label: item,
}));
