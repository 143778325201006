export enum ClaimReason {
  UPSLost = 'UPS_LOST',
  UPSDamaged = 'UPS_DAMAGED',
  USPSLost = 'USPS_LOST',
  USPSDamaged = 'USPS_DAMAGED',
}

export enum ClaimType {
  Lost = 'LOST',
  Damaged = 'DAMAGED',
}

export interface Item {
  description: string;
  amountRequested?: string;
  quantity: string;
  unitValue: string;
  sealType?: string;
  packageType?: string;
  replacementTrackingNumber?: string;
  replaceable?: 'true' | 'false';
  itemsMissing?: 'true' | 'false';
  dangerousContents?: 'HAZARD' | 'FIREARMS' | 'NONE';
  sealedSafe?: 'true' | 'false';
  purchaseDate?: string;
  dateDamageWasDiscovered?: string;
  currentLocationOfDamagedItem?: string;
  damagedPhoto?: Record<ItemPhotoType, File | null>;
  customSealType?: string;
  customPackageType?: string;
}

export enum PackagePhotoType {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  Label = 'LABEL',
  Packaging = 'PACKAGING_MATERIAL',
  BMC = 'BMC',
}

export enum ProofOfValuePhotoType {
  PovSalesReceipt = 'POV_SALES_RECEIPT',
  PovInvoiceOrBos = 'POV_INVOICE_OR_BOS',
  PovStatementOfValue = 'POV_STATEMENT_OF_VALUE',
  PovEstimatedRepairCost = 'POV_ESTIMATED_REPAIR_COST',
  PovCreditCardStatement = 'POV_CREDIT_CARD_STATEMENT',
  PovReceiptReconstruction = 'POV_RECEIPT_RECONSTRUCTION',
}

export enum ItemPhotoType {
  Inside = 'DAMAGED_ITEM_INSIDE',
  Outside = 'DAMAGED_ITEM_OUTSIDE',
}

export enum PhotoType {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  Packaging = 'PACKAGING_MATERIAL',
  Label = 'LABEL',
  BMC = 'BMC',
  DamagedItemInside = 'DAMAGED_ITEM_INSIDE',
  DamagedItemOutside = 'DAMAGED_ITEM_OUTSIDE',
  PovSalesReceipt = 'POV_SALES_RECEIPT',
  PovInvoiceOrBos = 'POV_INVOICE_OR_BOS',
  PovStatementOfValue = 'POV_STATEMENT_OF_VALUE',
  PovEstimatedRepairCost = 'POV_ESTIMATED_REPAIR_COST',
  PovCreditCardStatement = 'POV_CREDIT_CARD_STATEMENT',
  PovReceiptReconstruction = 'POV_RECEIPT_RECONSTRUCTION',
}
