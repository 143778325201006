import React from 'react';
import {
  Typography,
  Button,
  Box,
  InputAdornment,
  makeStyles,
  Link,
  Card,
  FormControl,
  Popover,
  Grid,
} from '@material-ui/core';
import { useField } from 'react-final-form';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { Input, Select } from 'components/form';
import NumberInputFieldWithActions from 'components/inputs/NumberInputFieldWithActions';
import Radio from 'components/form/Radio';
import RadioGroup from 'components/form/RadioGroup';
import { DateInputField } from 'components/inputs/DateInput';

import { ReactComponent as InfoIcon } from 'assets/shipments/infoIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/shipments/deleteIcon.svg';

import { required } from 'components/form/validationRules';
import { TwoLinesLabel } from 'components/common';

import { sealMaterials, packaging } from '../constants';
import ItemPhotoSection from './ItemPhotoSection';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4, 4, 4),
    gap: '24px',
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    margin: theme.spacing(0, -3),
    padding: '12px',
    borderRadius: '16px',
    backgroundColor: '#FAFAFC',
  },
  title: {
    display: 'flex',
    gap: '16px',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  descriptionLink: {
    display: 'flex',
    gap: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  inputsContainer: {
    display: 'flex',
    gap: '24px',
  },
  date: {
    width: '50%',
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  currencyIcon: {
    fontSize: 24,
    paddingBottom: 5,
  },
  link: {
    color: '#6C13F9',
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtext: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#969AB2',
  },
  addButton: {
    width: '100%',
  },
  deleteButton: {
    cursor: 'pointer',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    width: '256px',
  },
  popoverText: {
    fontSize: '13px',
    lineHeight: '16px',
  },
  imagesContainer: {
    display: 'flex',
    gap: '16px',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    flexWrap: 'nowrap',
  },
  radioLabel: {
    color: theme.palette.common.black,
    '&.Mui-focused': {
      color: theme.palette.common.black,
    },
  },
  itemIcon: {
    color: '#969AB2',
  },
}));

interface Props {
  isUPS?: boolean;
}
const DamagedItemSection = ({ isUPS }: Props) => {
  const classes = useStyles();

  const {
    input: { value: items, onChange },
  } = useField('items');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAddClick = () => {
    onChange([
      ...items,
      {
        description: '',
        quantity: '',
        unitValue: '',
        replaceable: 'false',
        itemsMissing: 'false',
        dangerousContents: 'NONE',
        sealedSafe: 'false',

        ...(!isUPS && {
          amount: '',
          purchaseDate: '',
        }),
      },
    ]);
  };

  const handleDelete = (index: number) => {
    const newItems = items.filter((item, i) => i !== index);
    onChange(newItems);
  };

  return (
    <Card title="Items" className={classes.card}>
      {items.map((item, index) => {
        const otherSealType = item.sealType === 'CUSTOM';
        const otherPackagingType = item.packageType === 'CUSTOM';

        return (
          <Box key={index} className={classes.container}>
            <Box className={classes.cardTitle}>
              <Box className={classes.title}>
                <FormatListBulletedIcon className={classes.itemIcon} />
                <Typography>ITEM {items?.length > 1 ? index + 1 : ''}</Typography>
              </Box>
              {items.length > 1 && (
                <DeleteIcon className={classes.deleteButton} onClick={() => handleDelete(index)} />
              )}
            </Box>
            <Box className={classes.description}>
              <>
                <Input
                  name={`items[${index}].description`}
                  label="Description"
                  fullWidth
                  validate={required}
                  inputProps={{
                    InputProps: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}>
                          <InfoIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <Popover
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus>
                  <Typography className={classes.popoverText}>
                    Detailed item descriptions are needed for all UPS claims to expedite processing.
                    Be as specific as possible (product name, brand name, serial number, size,
                    color, etc.) Additional items must be added separately.
                  </Typography>
                </Popover>
              </>

              <Typography className={classes.subtext}>
                Example: Old Navy men's classic crew neck jumper, hunter green, size M, 22231 BL.
              </Typography>

              <Box className={classes.descriptionLink}>
                <Typography className={classes.subtext}>
                  Be specific as possible: part #, serial #, model, color, size.
                </Typography>
                {isUPS && (
                  <Link
                    className={classes.link}
                    href="https://www.ups.com/us/en/support/file-a-claim/supporting-documents.page"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="always">
                    UPS Merchandise description
                  </Link>
                )}
              </Box>
            </Box>

            <Box className={classes.inputsContainer}>
              {!isUPS && (
                <NumberInputFieldWithActions
                  name={`items[${index}].amountRequested`}
                  label="Amount requested"
                  actionButtons
                  validate={required}
                  isInteger
                />
              )}

              <NumberInputFieldWithActions
                name={`items[${index}].quantity`}
                label="Quantity of items damaged"
                actionButtons
                validate={required}
                isInteger
              />

              <NumberInputFieldWithActions
                name={`items[${index}].unitValue`}
                label="Unit value"
                startAdornment={<span className={classes.currencyIcon}>$</span>}
                validate={required}
              />
            </Box>

            {isUPS && (
              <RadioGroup
                name={`items[${index}].itemsMissing`}
                labelClass={classes.radioLabel}
                label="Are any items missing?">
                <Radio label="Yes" name={`items[${index}].itemsMissing`} value={'true'} />
                <Radio label="No" name={`items[${index}].itemsMissing`} value={'false'} />
              </RadioGroup>
            )}

            {isUPS && (
              <RadioGroup
                name={`items[${index}].dangerousContents`}
                label="Does your package contain the following?"
                labelClass={classes.radioLabel}>
                <Radio
                  label="Hazardous materials / Unknown chemicals"
                  name={`items[${index}].dangerousContents`}
                  value={'HAZARD'}
                />
                <Radio
                  label="Firearms"
                  name={`items[${index}].dangerousContents`}
                  value={'FIREARMS'}
                />
                <Radio
                  label="None of the above"
                  name={`items[${index}].dangerousContents`}
                  value={'NONE'}
                />
              </RadioGroup>
            )}

            {isUPS && (
              <RadioGroup
                labelClass={classes.radioLabel}
                name={`items[${index}].sealedSafe`}
                label="Can the package be sealed and transported safely?">
                <Radio label="Yes" name={`items[${index}].sealedSafe`} value={'true'} />
                <Radio label="No" name={`items[${index}].sealedSafe`} value={'false'} />
              </RadioGroup>
            )}

            {!isUPS && (
              <Box className={classes.date}>
                <FormControl fullWidth>
                  <DateInputField
                    label="Purchase Date"
                    name={`items[${index}].purchaseDate`}
                    validate={required}
                  />
                </FormControl>
              </Box>
            )}

            <TwoLinesLabel primary="Upload photo" secondary="JPG, PNG, Max size 5MB " />

            <ItemPhotoSection index={index} />

            {isUPS && (
              <>
                <div className={classes.inputRow}>
                  <Select
                    name={`items[${index}].sealType`}
                    label="Package sealed"
                    options={sealMaterials}
                    validate={required}
                  />

                  <Select
                    name={`items[${index}].packageType`}
                    label="Material user or packing"
                    options={packaging}
                    validate={required}
                  />
                </div>

                {(otherSealType || otherPackagingType) && (
                  <div className={classes.inputRow}>
                    {otherSealType ? (
                      <Grid xs={6}>
                        <Input
                          name={`items[${index}].customSealType`}
                          label="Enter how your package sealed"
                          validate={required}
                        />
                      </Grid>
                    ) : (
                      <Grid xs={6}></Grid>
                    )}

                    {otherPackagingType && (
                      <Grid xs={6}>
                        <Input
                          name={`items[${index}].customPackageType`}
                          label="Enter material used for packing"
                          validate={required}
                        />
                      </Grid>
                    )}
                  </div>
                )}

                <Grid container className={classes.inputRow}>
                  <Grid xs={6}>
                    <FormControl fullWidth>
                      <DateInputField
                        label="Date damage was discovered"
                        name={`items[${index}].dateDamageWasDiscovered`}
                        validate={required}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={6}>
                    <Input
                      name={`items[${index}].currentLocationOfDamagedItem`}
                      label="Current location of the damaged package"
                      fullWidth
                      validate={required}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        );
      })}
      <Button
        variant="outlined"
        size="large"
        className={classes.addButton}
        onClick={handleAddClick}>
        Add Item
      </Button>
    </Card>
  );
};

export default DamagedItemSection;
