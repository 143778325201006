import React, { memo, useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, makeStyles } from '@material-ui/core';
import { useDataProvider, ReferenceArrayInput, useVersion } from 'react-admin';
import { keyBy, isEqual, debounce } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';

import { GroupInfo } from 'pages/orders/components/group/GroupedOrders';
import {
  StoreField,
  OrderIdField,
  DateField,
  CustomerField,
  PriceField,
  ActionField,
} from 'components/fields';
import ColumnsConfigButton from 'components/layout/ColumnsConfigButton';
import { Datagrid } from 'components/list';
import { CopyTrackNumberActionField } from '../CopyTrackNumber';
import { PrintPackingSlipActionField } from '../PackingSlip';
import { ReprintLabelActionField } from '../ReprintLabel';
import { ReturnLabelActionField } from '../ReturnLabel';
import ShipmentStatusField from '../ShipmentStatusField';
import TrackProviderField from '../TrackProviderField';
import { VoidLabelActionField } from '../VoidLabel';
import { openDoc } from 'store/actions/lightboxActions';
import FilterChips from 'components/list/FilterChips';
import { useSilentControllerProps } from 'hooks/useSilentControllerProps';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import FilterDateInput from 'components/inputs/FilterDateInput';
import { BulkPrintPackingSlipsButton } from 'pages/orders/components/group/BulkPrintPackingSlipsButton';
import BulkActions from '../../../../components/list/BulkActions';
import useCustomNotify from 'hooks/useCustomNotify';
import ReprintAlertDialog from '../ReprintAlertDialog';
import { OpenClaimActionField } from '../../components/OpenClaim';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    '& > p': {
      fontWeight: 'normal',
    },
  },
  main: {
    display: 'flex',
    marginTop: 20,
  },
  content: {
    marginTop: 0,
    transition: theme.transitions.create('margin-top'),
    position: 'relative',
    overflow: 'visible',
    flex: '1 1 auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
  notifyReturn: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  multipleButton: {
    padding: theme.spacing(1, 2.5),
    lineHeight: '16px',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
}));

const fetchGroupedShipments = (dataProvider, filterValues, sort, page, perPage, id) => {
  const { market, account, date, status } = filterValues;

  return dataProvider.get('groupShipments', {
    id,
    sort: sort.field,
    [`${sort.field}.dir`]: sort.order,
    page,
    size: perPage,
    'filter.market': market?.join(','),
    'filter.date.to': date?.to,
    'filter.date.from': date?.from,
    'filter.account.type': account?.join(','),
    'filter.status': status?.join(','),
  });
};

const ReprintButton = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const notify = useCustomNotify();
  const { id } = useParams();
  const { containsShipped, selectedIds, currentTotal, filterValues, sort, ...rest } = props;

  const { market, account, date, status } = filterValues;

  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [allShipments, setAllShipments] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentTotal < 1) return;
    setLoading(true);
    fetchGroupedShipments(dataProvider, filterValues, sort, 1, currentTotal, id)
      .then(({ data: { items } }) => setAllShipments(items))
      .catch((error) => notify(error.message))
      .finally(() => setLoading(false));
  }, [currentTotal, dataProvider, market, account, date, status, id, sort]); //eslint-disable-line

  const selectedShipments = useMemo(
    () => allShipments?.filter((shipment) => selectedIds?.includes(shipment.id)),
    [allShipments, selectedIds]
  );

  const printLabel = () => {
    setLoading(true);
    const labelsToReprint = selectedShipments
      .filter((shipment) => shipment.shipmentStatus.status === 'SHIPPED')
      .map(({ id }) => id);
    dataProvider
      .create('groupLabels', { data: labelsToReprint })
      .then(({ data: { url } }) => {
        dispatch(openDoc({ name: 'Shipment labels', url }));
      })
      .catch((error) => notify(error.message))
      .finally(() => setLoading(false));
  };

  const handleClick = () => {
    const hideAlert = JSON.parse(localStorage.getItem('hideReprintAlert') ?? 'false');
    hideAlert ? printLabel() : setOpenAlert(true);
  };

  return (
    <>
      <Button
        className={classes.multipleButton}
        {...rest}
        color="primary"
        variant="contained"
        size="small"
        onClick={handleClick}
        disabled={
          !containsShipped ||
          !selectedShipments?.some((shipment) => shipment.shipmentStatus.status === 'SHIPPED') ||
          loading
        }>
        Re-print&nbsp;labels
      </Button>
      <ReprintAlertDialog open={openAlert} printLabel={printLabel} setOpenAlert={setOpenAlert} />
    </>
  );
};

const GroupedShipments = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const version = useVersion();
  const dataProvider = useDataProvider();
  const { id } = useParams();

  const perPage = 25;

  const filterValues = useSelector(
    ({ filters }) => ({
      ...filters['shipments'],
    }),
    shallowEqual
  );
  const { market, account, date, status } = filterValues;

  const withFilters = useMemo(() => market || date || status || account, []); // eslint-disable-line

  const [shipments, setShipments] = useState<any>([]);
  const [sort, setSort] = useState<{ field: string; order: string }>({
    field: 'purchaseDate',
    order: 'DESC',
  });
  const [page, setPage] = useState<number>(1);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [group, setGroup] = useState<any>({});
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [groupIds, setGroupIds] = useState<any>([]);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [containsShipped, setContainsShipped] = useState<boolean>(false);

  useEffect(() => {
    dataProvider.getNest('groupShipmentsInfo', { id }).then(({ data }) => {
      setGroup(data);
    });
  }, [dataProvider, id]);

  useEffect(() => {
    dataProvider
      .getIds('groupedShipmentsIds', {
        id,
        'filter.market': market?.join(','),
        'filter.date.from': date?.from,
        'filter.date.to': date?.to,
        'filter.status': status?.join(','),
        'filter.account.type': account?.join(','),
      })
      .then(({ data }) => {
        setGroupIds(data);
        setSelectedIds(data);
      });
  }, [dataProvider, id, version, market, date, status, account]);

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 400 &&
      page * perPage < currentTotal
    ) {
      setIsScrolled(true);
      setPage((prev) => prev + 1);
    }
  }, 500);

  useEffect(() => {
    fetchGroupedShipments(dataProvider, filterValues, sort, page, perPage, id).then(
      ({ data, total }) => {
        const { items, containsShipped } = data;
        isScrolled ? setShipments((prev) => [...prev, ...items]) : setShipments(items);
        setIsScrolled(false);
        setCurrentTotal(total);
        setContainsShipped(containsShipped);
      }
    );
  }, [dataProvider, id, page, sort, version, market, account, date, status]); //eslint-disable-line

  const updateSort = (field, order) => {
    setSort({ field, order });
  };

  const controllerProps = useSilentControllerProps('shipments');
  const { setFilters } = controllerProps;

  const filters = React.Children.toArray(
    <>
      <FilterDateInput icon={AccessTimeIcon} label="Date" source="date" allowEmpty />
      <ReferenceArrayInput
        icon={StorefrontIcon}
        basePath={props.basePath}
        label="Store"
        source="market"
        reference="sellingChannels"
        perPage={999}
        allowEmpty>
        <ChipsArrayInput
          optionValue="id"
          optionAvatar="provider.smallLogoUrl"
          optionLabel="number"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        icon={LocalShippingOutlinedIcon}
        basePath={props.basePath}
        label="Shipping"
        source="account"
        reference="deliveryProviders"
        filter={{ marketingOnly: false }}
        perPage={999}
        allowEmpty>
        <ChipsArrayInput optionValue="id" optionAvatar="logoUrl" optionLabel="code" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        icon={CheckCircleOutlineIcon}
        basePath={props.basePath}
        label="Status"
        source="status"
        reference="shipmentStatuses"
        allowEmpty>
        <ChipsArrayInput optionValue="status" optionLabel="name" />
      </ReferenceArrayInput>
    </>
  )[0].props.children;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GroupInfo
          title="Grouped Shipments"
          group={group}
          basePath="/shipments"
          to={withFilters ? 'list' : ''}
        />
      </div>
      <FilterChips
        resource="shipments"
        filterValues={filterValues}
        filters={filters}
        setFilters={setFilters}
      />
      <div className={classes.main}>
        <Card key={props.version} className={classes.content}>
          <BulkActions {...props} selectedIds={selectedIds}>
            <>
              <BulkPrintPackingSlipsButton primary={false} ids={selectedIds} type="shipments" />
              <ReprintButton
                selectedIds={selectedIds}
                containsShipped={containsShipped}
                currentTotal={currentTotal}
                filterValues={filterValues}
                sort={sort}
              />
            </>
          </BulkActions>
          <Datagrid
            {...props}
            rowClick={(id) => history.push(`/shipments/${id}/show`, { isFromGrouped: true })}
            data={keyBy(shipments, 'id')}
            ids={shipments.map(({ id }) => id)}
            selectedIds={selectedIds}
            basePath="shipments"
            currentSort={sort}
            setSort={(field, order) => updateSort(field, order)}
            resource="shipments"
            hasBulkActions
            onToggleItem={(id) => {
              selectedIds.includes(id)
                ? setSelectedIds(selectedIds.filter((item) => item !== id))
                : setSelectedIds([...selectedIds, id]);
            }}
            onSelect={() =>
              isEqual(groupIds, selectedIds) ? setSelectedIds([]) : setSelectedIds(groupIds)
            }
            {...props}>
            <StoreField
              label="Store"
              source="store"
              tooltipSource="order.marketAccount.alias"
              logoSource="order.marketAccount.provider.smallLogoUrl"
              sortBy="orderMarketAccount.provider"
            />
            <OrderIdField label="Order No." source="order.marketplaceOrderId" baseSource="order" />
            <DateField source="order.purchaseDate" label="Ordered" />
            <DateField source="shipmentDate" label="Shipped" dateOnly />
            <CustomerField
              source="customer"
              addressSource="customer.address"
              label="Recipient"
              autoSize
              sortable={false}
            />
            <TrackProviderField
              label="Provider"
              source="account.provider.code"
              sortBy="account.provider"
              sortable={false}
            />
            <ShipmentStatusField label="Status" typeSource="type" statusSource="shipmentStatus" />
            <PriceField source="price" label="Price" textAlign="right" sortable={false} />
            <PriceField
              source="paymentDetails.details.savings"
              label="Savings"
              textAlign="right"
              sortable={false}
            />
            <ActionField header={ColumnsConfigButton} textAlign="center">
              <ReturnLabelActionField />
              <ReprintLabelActionField />
              <PrintPackingSlipActionField />
              <VoidLabelActionField />
              <CopyTrackNumberActionField />
              <OpenClaimActionField />
            </ActionField>
          </Datagrid>
        </Card>
      </div>
    </>
  );
};

export default memo(GroupedShipments);
