import React from 'react';

import BackActionButton from 'components/buttons/BackActionButton';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
  },
}));

const PageHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BackActionButton />
      <Typography className={classes.title}>Submit claim</Typography>
    </div>
  );
};

export default PageHeader;
