import React from 'react';
import { useDispatch } from 'react-redux';
import { hideNotification } from 'store/actions/notificationActions';

import { createStyles, makeStyles, SnackbarContent, IconButton, Theme } from '@material-ui/core';
import {
  CloseOutlined,
  ErrorOutlineOutlined,
  ReportProblemOutlined,
  InfoOutlined,
  CheckCircleOutline,
} from '@material-ui/icons';

import NoInternetConnection from 'icons/NoInternetConnection';
import { NotificationType } from 'store/actions/notificationActions';
import { ReactComponent as Coins } from 'assets/general/icons/Coins.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    messageContent: {
      display: 'flex',
      justifyContent: 'center',
      // alignItems: "center",
      width: '100%',
    },
    messageContentTitle: {
      marginLeft: theme.spacing(1),
      fontWeight: 'normal',
      textAlign: 'center',
    },
  })
);

interface StyleProps {
  type: NotificationType;
}

const useStylesSnackbarContent = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    backgroundColor: '#6C13F9',
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: 16,
    width: (props) => (props.type === 'claim' ? 'auto' : '555px'),
  },

  message: {
    width: '100%',
    paddingRight: theme.spacing(4),
    paddingLeft: (props) => (props.type === 'claim' ? theme.spacing(0) : theme.spacing(4)),
  },

  action: {
    position: 'relative',
    '&>button': {
      color: theme.palette.common.white,
      position: 'absolute',
      top: '6px',
      right: '0px',
    },
  },
}));

const CommonNotification = (props) => {
  const { notification } = props;

  const classes = useStyles();
  const classesSnackbarContent = useStylesSnackbarContent(notification);
  const dispatch = useDispatch();

  const handleNotificationClose = (id: string) => () => {
    dispatch(hideNotification(id));
  };

  return (
    <SnackbarContent
      key={notification.id}
      message={
        <div className={classes.messageContent}>
          {(!notification.type || notification.type === 'info') && <InfoOutlined />}
          {notification.type === 'warning' && <ReportProblemOutlined />}
          {notification.type === 'success' && <CheckCircleOutline />}
          {notification.type === 'error' && <ErrorOutlineOutlined />}
          {notification.type === 'connection' && <NoInternetConnection />}
          {notification.type === 'billing' && <Coins />}
          {notification.type === 'claim' && null}

          <div className={classes.messageContentTitle}>{notification.content}</div>
        </div>
      }
      classes={classesSnackbarContent}
      action={
        <IconButton size="small" onClick={handleNotificationClose(notification.id)}>
          <CloseOutlined fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default CommonNotification;
