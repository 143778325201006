import React from 'react';
import { makeStyles } from '@material-ui/core';
import MUISkeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: theme.spacing(3),
    backgroundColor: '#D2D2D2',
    opacity: 0.5,
  },
}));

export const Skeleton = (props: SkeletonProps) => {
  const classes = useStyles();

  return <MUISkeleton {...props} className={classes.skeleton} animation="wave" />;
};
