import React, { memo, useCallback } from 'react';
import {
  makeStyles,
  TextField,
  FormHelperText,
  InputLabel,
  IconButton,
  Box,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { RemoveOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { Field } from 'react-final-form';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 24,
    fontWeight: 700,
  },
  label: {
    fontSize: 12,
    color: '#969AB2',
  },
  root: {
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid #E1E1E1',
    },
    '&.MuiInput-underline:hover:before': {
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    height: 32,
    width: 32,
    backgroundColor: 'transparent',
    border: '1px solid #E1E1E1',
    borderRadius: 45,
    boxSizing: 'border-box',
    padding: 0,
    minWidth: 32,
    cursor: 'pointer',
  },
  removeButton: {
    margin: theme.spacing(0, 1),
  },
}));

const NumberInputFieldWithActions = ({
  name,
  label = '',
  isInteger = false,
  actionButtons = false,
  ...rest
}) => {
  const classes = useStyles();

  const formatValue = useCallback((value: string) => {
    return !isInteger
      ? value.replace(/[^\d.]/g, '').replace(/\.(?=.*\.)/g, '')
      : value.replace(/[^\d]/g, '');
  }, []); //eslint-disable-line

  return (
    <Field
      name={name}
      render={({ input: { name, onChange, value }, meta: { touched, error } }) => {
        const isError = !!(touched && error);

        const handleChange = (event) => {
          if (rest.customOnChange) {
            rest.customOnChange(formatValue(event.target.value));
          } else {
            onChange(formatValue(event.target.value));
          }
        };

        const handleActionButton = (currentValue) => {
          if (rest.customOnChange) {
            rest.customOnChange(formatValue(currentValue));
          } else {
            onChange(formatValue(currentValue));
          }
        };

        const handleIncrementClick = () => {
          if (!value) {
            handleActionButton('1');
          } else {
            const calculatedValue = Number(value) + 1;
            handleActionButton(String(calculatedValue));
          }
        };

        const handleDecrementClick = () => {
          if (!value || value <= 1) {
            handleActionButton('0');
          } else {
            const calculatedValue = Number(value) - 1;
            handleActionButton(String(calculatedValue));
          }
        };

        return (
          <Box>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <TextField
              variant="standard"
              placeholder="0"
              value={value}
              onChange={handleChange}
              name={name}
              type="text"
              InputProps={{
                classes: {
                  input: classes.input,
                  root: classes.root,
                  ...(rest.classes ?? {}),
                },
                startAdornment: rest.startAdornment,
                ...(actionButtons && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        classes={{ root: classNames(classes.button, classes.removeButton) }}
                        onClick={handleDecrementClick}>
                        <RemoveOutlined />
                      </IconButton>
                      <IconButton classes={{ root: classes.button }} onClick={handleIncrementClick}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }),
              }}
              fullWidth
            />
            {isError && <FormHelperText error>{error}</FormHelperText>}
          </Box>
        );
      }}
      {...rest}
    />
  );
};

export default memo(NumberInputFieldWithActions);
