import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ValidationErrors } from 'final-form';

import SectionCard from './SectionCard';
import { ImageInputField } from './ImageInputField';
import { TwoLinesLabel } from 'components/common';
import { ClaimReason, PhotoType, ClaimType } from '../types';
import { ReactComponent as ProofOfValue } from 'assets/claims/proof-of-value.svg';
import { ReactComponent as ProofOfValueTitle } from 'assets/claims/proof-of-value-title.svg';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.secondary.contrastText,
  },
  icon: {
    color: theme.palette.secondary.contrastText,
  },
  description: {
    paddingBottom: theme.spacing(2),
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: theme.spacing(1.5),
  },
}));

interface Props {
  claimReason: ClaimReason;
  errors: ValidationErrors;
  submitFailed: boolean;
}

const ProofOfValueSection = ({ claimReason, errors, submitFailed }: Props) => {
  const classes = useStyles();

  const claimType = claimReason === ClaimReason.USPSDamaged ? ClaimType.Damaged : ClaimType.Lost;

  const validationError = useMemo(() => {
    const validationError = [
      errors.proofOfValue?.POV_SALES_RECEIPT,
      errors.proofOfValue?.POV_INVOICE_OR_BOS,
      errors.proofOfValue?.POV_STATEMENT_OF_VALUE,
      errors.proofOfValue?.POV_ESTIMATED_REPAIR_COST,
      errors.proofOfValue?.POV_CREDIT_CARD_STATEMENT,
      errors.proofOfValue?.POV_RECEIPT_RECONSTRUCTION,
    ].filter(Boolean).length;

    return validationError === 6 && submitFailed
      ? 'At least one image with proof has to be uploaded'
      : '';
  }, [errors, submitFailed]);

  return (
    <SectionCard title="Proof of value" icon={<ProofOfValueTitle />}>
      <TwoLinesLabel
        primary="Upload proofs of package value"
        secondary="JPG, PNG, Max size 5MB"
        className={classes.description}
      />

      <div className={classes.row}>
        <ImageInputField
          name="proofOfValue.POV_SALES_RECEIPT"
          text="Sales receipt"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovSalesReceipt}
          sectionType="package"
          claimType={claimType}
          isRequired={false}
        />

        <ImageInputField
          name="proofOfValue.POV_INVOICE_OR_BOS"
          text="Paid invoice or paid bill of sale"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovInvoiceOrBos}
          sectionType="package"
          claimType={claimType}
          isRequired={false}
        />

        <ImageInputField
          name="proofOfValue.POV_STATEMENT_OF_VALUE"
          text="Statement of value from a reputable dealer"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovStatementOfValue}
          sectionType="package"
          claimType={claimType}
          isRequired={false}
        />
      </div>

      <div className={classes.row}>
        <ImageInputField
          name="proofOfValue.POV_ESTIMATED_REPAIR_COST"
          text="Estimates of repair costs from a reputable dealer"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovEstimatedRepairCost}
          sectionType="package"
          claimType={claimType}
          isRequired={false}
        />

        <ImageInputField
          name="proofOfValue.POV_CREDIT_CARD_STATEMENT"
          text="Credit card billing statement"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovCreditCardStatement}
          sectionType="package"
          claimType={claimType}
          isRequired={false}
        />

        <ImageInputField
          name="proofOfValue.POV_RECEIPT_RECONSTRUCTION"
          text="Non-negotiable documents reconstruction costs"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovReceiptReconstruction}
          sectionType="package"
          claimType={claimType}
          isRequired={false}
        />
      </div>

      {!!validationError && <Typography color="error">{validationError}</Typography>}
    </SectionCard>
  );
};

export default ProofOfValueSection;
