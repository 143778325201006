import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ValidationErrors } from 'final-form';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import SectionCard from './SectionCard';
import { ImageInputField } from './ImageInputField';
import { TwoLinesLabel } from 'components/common';

import { ReactComponent as Top } from 'assets/claims/top.svg';
import { ReactComponent as Bottom } from 'assets/claims/bottom.svg';
import { ReactComponent as Packaging } from 'assets/claims/packaging.svg';
import { ReactComponent as Label } from 'assets/claims/label.svg';
import { ReactComponent as BMC } from 'assets/claims/bmc.svg';
import { Package } from 'icons';

import { PhotoType, ClaimType } from '../types';

const useStyles = makeStyles((theme) => ({
  topRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: theme.spacing(1.5),
  },
  bottomRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  description: {
    paddingBottom: theme.spacing(2),
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: theme.spacing(1.5, 3),
    backgroundColor: '#FAFAFC',
    borderRadius: '16px',
  },
  warningIcon: {
    color: theme.palette.secondary.contrastText,
  },
}));

interface Props {
  errors: ValidationErrors;
  submitFailed: boolean;
  isUPS?: boolean;
}

const PackagePhotoSection = ({ errors, submitFailed, isUPS = false }: Props) => {
  const classes = useStyles();

  const validationError = useMemo(() => {
    const validationError =
      errors.packagePhotos?.TOP ||
      errors.packagePhotos?.BOTTOM ||
      errors.packagePhotos?.LABEL ||
      errors.packagePhotos?.PACKAGING_MATERIAL ||
      errors.packagePhotos?.BMC;

    return validationError && submitFailed ? 'All photos have to be uploaded' : '';
  }, [errors, submitFailed]);

  return (
    <SectionCard icon={<Package />} title="Package">
      <TwoLinesLabel
        primary="Upload photos of your package"
        secondary="JPG, PNG, Max size 5MB"
        className={classes.description}
      />

      <div className={classes.topRow}>
        <ImageInputField
          name="packagePhotos.TOP"
          text="Top and two sides of the box"
          icon={<Top />}
          photoType={PhotoType.Top}
          sectionType="package"
          claimType={ClaimType.Damaged}
        />

        <ImageInputField
          name="packagePhotos.BOTTOM"
          text="Bottom and the opposite sides of the box"
          icon={<Bottom />}
          photoType={PhotoType.Bottom}
          sectionType="package"
          claimType={ClaimType.Damaged}
        />

        <ImageInputField
          name="packagePhotos.PACKAGING_MATERIAL"
          text="Packaging material"
          icon={<Packaging />}
          photoType={PhotoType.Packaging}
          sectionType="package"
          claimType={ClaimType.Damaged}
        />
      </div>

      <div className={classes.bottomRow}>
        <ImageInputField
          name="packagePhotos.LABEL"
          text="Shipping label with tracking number"
          icon={<Label />}
          photoType={PhotoType.Label}
          sectionType="package"
          claimType={ClaimType.Damaged}
        />

        <ImageInputField
          name="packagePhotos.BMC"
          text="Box manufacture certificate (BMC)"
          icon={<BMC />}
          photoType={PhotoType.BMC}
          sectionType="package"
          claimType={ClaimType.Damaged}
        />
      </div>

      {!!isUPS && (
        <div className={classes.warningContainer}>
          <ReportProblemOutlinedIcon className={classes.warningIcon} />
          <Typography>
            Please ensure the original packaging and contents are available for further inspection
            by UPS.
          </Typography>
        </div>
      )}

      {!!validationError && <Typography color="error">{validationError}</Typography>}
    </SectionCard>
  );
};

export default PackagePhotoSection;
